import { createBrowserRouter, RouterProvider } from 'react-router-dom';

//import BlogPage, { loader as postsLoader } from './pages/Blog';
import HomePage from './pages/Home';
//import PostPage, { loader as postLoader } from './pages/Post';
import RootLayout from './pages/Root';
import { lazy, Suspense } from 'react';
import Login from './pages/Login';
const BlogPage  = lazy(() => import('./pages/Blog'))
const PostPage  = lazy(() => import('./pages/Post'))
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'posts',
        children: [
          { index: true, element: <Suspense fallback={<p>Loading...</p>}><BlogPage /></Suspense>, 
          
          loader: ()=> import('./pages/Blog').then(module=> module.loader()) },
          { path: ':id', element: <Suspense  fallback={<p>Loading...</p>}><PostPage /></Suspense>, 
          loader: (meta)=> import('./pages/Post').then(module=> module.loader(meta)) },
        ],
      },
      {
        path:'login',
        element: <Login/>
      }
    ],
  },
]);

function App() {
  return <RouterProvider router={router}
    // basename={'/react/3'} 
   
   />;
}

export default App;
