import React, { useEffect, useRef, useState } from 'react'
import Input from '../components/Input'
import Swal from 'sweetalert2'

async function loginUser(credentials) {
  //  return fetch('https://www.mecallapi.com/api/login', {

     return fetch('https://www.in2pcstrials.co.uk/api/users/login.php', {

      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
const Login = () => {
const username = useRef();
const password = useRef();
const [products, setProducts] = useState([])
const [isLoading, setIsLoading]= useState(false)
const [httpError, setHttpError]= useState(null)




    const submitHandler = async (event) => {
        event.preventDefault();
        console.log(username.current.value + ' | ' + password.current.value)
        const user_name = username.current.value
        const user_password = password.current.value
        const response = await loginUser({
            user_name,
            user_password
          });
          if ('accessToken' in response) {
            Swal.fire("Success", response.message, "success", {
              buttons: false,
              timer: 2000,
            })
            .then((value) => {
              localStorage.setItem('accessToken', response['accessToken']);
              localStorage.setItem('user', JSON.stringify(response['user']));
              window.location.href = "/posts";
            });
          } else {
            Swal.fire("Failed", response.message, "error");
          }
        }
        //now connect to API
      

      
  return (
    <div>
      <h1>Login Page</h1>
      <form className='form' onSubmit={submitHandler}><div className='login-form'>
      <Input ref={username} label='Username' input={{id:'your-username', type:"text", placeholder:'username'}} />
      <Input ref={password} label='Password' input={{id:'your-password', type:"password", placeholder:'password'}} />
      <button>Login</button>
      </div>
      
   
      </form>
    </div>
  )
}

export default Login
